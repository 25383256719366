body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.root-div {
  overflow: hidden; /* or scroll, auto */
}

@font-face {
  font-family: 'Pretendard-Black'; /* ����� ��Ʈ�� �̸��� ���� */
  src: url('./components/fonts/Pretendard-Black.otf'); /* ��Ʈ ������ ��ġ */
}
@font-face {
  font-family: 'Pretendard-Bold'; /* ����� ��Ʈ�� �̸��� ���� */
  src: url('./components/fonts/Pretendard-Bold.otf'); /* ��Ʈ ������ ��ġ */
}

@font-face {
  font-family: 'Pretendard-SemiBold';
  src: url('./components/fonts/Pretendard-SemiBold.otf');
}


