.reaultBackground
{
    width:100%;
    height:100vh;
    background-size: cover;
    background-image: url(../assets/Quiz/ImgBgMain2.svg);
    background-repeat: no-repeat; 
    background-position: center;
    padding: 50px 0px;
    text-align: center;
    margin: 0 auto;
}

.cardParent {    
    display: flex;
    justify-content: center;
    align-items: center;
}
.ResultText
{
    width:80%;
    margin: 5% auto;
    font-size:130%;
    color: #161616;
    font-family: "Pretendard-Bold";
}

.card {
    width: 70%;
    height:40vh;
    padding: 8% 6%;
    background-size: contain;
    background-image: url(../assets/FinishCard/BtnCard.svg);
    background-repeat: no-repeat; 
    background-position: center;
    
}


.card p {
    margin: 2%;
    color: #f3ffe1;
    font-size:1.2rem;
    text-align: center;
    font-family: "Pretendard-Bold";
    pointer-events : none;
    user-select : none;
}

.restartBtn {
    width: 60%;
    height:12vh;
    padding: 5% 10%;
    margin: 5% 0 0 0;
    border: none;
    background-size: contain;
    background-image: url(../assets/FinishCard/BtnRestart.svg);
    background-repeat: no-repeat; 
    background-position: center;
    cursor: pointer;
    transition: background-color 0.3s;
}

.restartBtn:hover {
   
    background-image: url(../assets/FinishCard/BtnRestartClick.svg);

}

.inputName {
    width: 80%;
    height: 8.5vh;
    padding: 10px;
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
    background: none; /* Removes the default background */
    border: none; /* Removes the default border */
    padding: 0; /* Removes any default padding */
    color: #2f5e1d;
    font-size: 20px;
    font-family: "Pretendard-Semibold";
    background-size: contain;
    background-image: url(../assets/Quiz/BtnInputBox.svg);
    background-repeat: no-repeat; 
    background-position: center;
}

.inputName:focus {
    outline: none;
}

.CardTextAlign
{
    margin-top:95% ;
}
@media (max-height: 500px) {
    .card {
        width: 0%;
        height:0;
        padding: 0;

    }

    .card p {
        margin: 0;
        font-size:0%;
    }

    .restartBtn {
        width: 0%;
        height:0vh;
        padding: 0;
        margin: 0;
   
    }

    .inputName {
        width: 80%;
        height: 20vh;

    }

    .CardTextAlign
    {
        margin:0% ;
        font-size:0%;
    }
}

@media (max-height: 750px) {
    .CardTextAlign
    {
        margin-top:90% ;
    }

    .card p {
        font-size:1rem;
        
    }
}

@media  (max-height: 670px) {
    .CardTextAlign
    {
        margin-top:78% ;
    }

    .card p {
        font-size:0.9rem;
        
    }
}








