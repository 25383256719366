
  


.disable-button {
    background: none; /* Removes the default background */
    border: none; /* Removes the default border */
   
    background-size: cover;
    background-image: url(../assets/AR/BtnBack.svg);
    background-repeat: no-repeat; 
    background-position: center;
    width: 180px; /* Set the desired width of the button */
    height: 66px; /* Set the desired height of the button */
    cursor: pointer; /* Ensure the cursor changes to a pointer when hovered */
    padding-left: 5%;
    padding-right: 5%;
    transition: background-color 0.3s;
}

.disable-button:hover {
  background-image: url(../assets/AR/BtnBackClick.svg);
  
}