.QuizeBackground
{
    width:100%;
    height:100vh;
    background-size: cover;
    background-image: url(../assets/Quiz/ImgBgMain2.svg);
    background-repeat: no-repeat; 
    background-position: center;
    
    text-align: center;
    margin: 0 auto;
}
.QuizContents {

    width:73%;
    height: 40vh;
    background-size: contain;
    background-image: url(../assets/Quiz/ImgQuizBox.svg);
    background-repeat: no-repeat; 
    background-position: center;
    padding: 10%;
    text-align: center;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

}



.ResultInCorrect {
    background: none; /* Removes the default background */
    border: none; /* Removes the default border */
    background-position: center;
    background-size: contain;
    background-image: url(../assets/Quiz/ImgTextWrongAnswer.svg);
    background-repeat: no-repeat; 
    width: 90%; /* Set the desired width of the button */
    height: 4vh; /* Set the desired height of the button */
    margin: 0 auto;

}

.ResultCorrect {
    background: none; /* Removes the default background */
    border: none; /* Removes the default border */
    background-position: center;
    background-size: contain;
    background-image: url(../assets/Quiz/ImgTextCorrectAnswer.svg);
    background-repeat: no-repeat; 
    width: 90%; /* Set the desired width of the button */
    height: 4vh;/* Set the desired height of the button */
    margin: 0 auto;

}

.layout-contents {
    width: 80%;
    margin: 0 auto;
    text-align: center;
}

.button-contents {
    width: 90%;
    margin: 0 auto;
}


.inputAnswer {
    width: 80%;
    height: 7vh;
    padding: 10px;
    font-size: 120%;
    font-family: "Pretendard-Semibold";
    text-align: center;
    margin-bottom: 10px;
    background: none; /* Removes the default background */
    border: none; /* Removes the default border */
    padding: 0; /* Removes any default padding */
    color: #2f5e1d;
    background-size: contain;
    background-image: url(../assets/Quiz/BtnInputBox.svg);
    background-repeat: no-repeat; 
    background-position: center;
}

.inputAnswer:focus {
    outline: none;
}
.button-group {
    display: flex;
    justify-content: center;
    gap: 5%;
    flex-wrap: wrap; /* To ensure buttons wrap to the next line on smaller screens */
}
.submit-button {
    background: none; /* Removes the default background */
    border: none; /* Removes the default border */

    background-size: contain;
    background-image: url(../assets/Quiz/BtnSubmit.svg);
    background-repeat: no-repeat; 
    background-position: center;
    width: 80%; /* Set the desired width of the button */
    height: 7vh; /* Set the desired height of the button */
    cursor: pointer; /* Ensure the cursor changes to a pointer when hovered */
    transition: background-color 0.3s;
    padding:2%;
    margin-bottom: 5%;
}

.AR-button
{
    background: none; /* Removes the default background */
    border: none; /* Removes the default border */
    padding: 0; /* Removes any default padding */
    margin: 0; /* Removes any default margin */
    background-size: contain;
    background-image: url(../assets/Quiz/BtnShowAR.svg);
    background-repeat: no-repeat; 
    background-position: center;
    width: 45%; /* Set the desired width of the button */
    height: 6.5vh; /* Set the desired height of the button */
    cursor: pointer; /* Ensure the cursor changes to a pointer when hovered */
    transition: background-color 0.3s;
}
.AR-button:hover {
    background-image: url(../assets/Quiz/BtnShowARClick.svg); /* Change background image on hover */

}
.Hint-button {
    background: none; /* Removes the default background */
    border: none; /* Removes the default border */
    padding: 0; /* Removes any default padding */
    margin: 0; /* Removes any default margin */
    background-size: contain;
    background-image: url(../assets/Quiz/BtnHint.svg);
    background-repeat: no-repeat; 
    background-position: center;
    width: 45%; /* Set the desired width of the button */
    height: 6.5vh; /* Set the desired height of the button */
    cursor: pointer; /* Ensure the cursor changes to a pointer when hovered */
}

.Hint-button:hover {

    background-image: url(../assets/Quiz/BtnHintClick.svg);

}

.Next-button {
    background: none; /* Removes the default background */
    border: none; /* Removes the default border */
    
    background-size: contain;
    background-image: url(../assets/Quiz/BtnNextQuiz.svg);
    background-repeat: no-repeat; 
    background-position: center;
    width: 80%; /* Set the desired width of the button */
    height: 7vh; /* Set the desired height of the button */
    cursor: pointer; /* Ensure the cursor changes to a pointer when hovered */
    transition: background-color 0.3s;
    padding: 5%;
    margin: 3% 0%;

}



.Next-button:hover {

    background-image: url(../assets/Quiz/BtnNextQuizClick.svg);

}


.Result-button {
    background: none; /* Removes the default background */
    border: none; /* Removes the default border */
    
    background-size: contain;
    background-image: url(../assets/Quiz/BtnResult.svg);
    background-repeat: no-repeat; 
    background-position: center;
    width: 80%; /* Set the desired width of the button */
    height: 7vh; /* Set the desired height of the button */
    cursor: pointer; /* Ensure the cursor changes to a pointer when hovered */
    transition: background-color 0.3s;
    padding: 5%;
    margin: 3% 0%;

}

.Result-button:hover {

    background-image: url(../assets/Quiz/BtnResultClick.svg);

}
.custom-modal {
    position: fixed;
    bottom: 0; /* ȭ�� �ϴܿ� ���� */
    left: 0;
    right: 0;
    padding: 20px;
    border: none;
    background-color: transparent;
    background-size: contain;
    background-image:url(../assets/Quiz/ImgBgHint.svg);
    background-repeat: no-repeat; 
    max-width: 100%;
    min-width: 200px;
    height: 100vh;
    margin: auto;
    text-align: center;
    animation: slideUp 0.3s ease-in-out;
    transform: translateY(60%);
  }

@keyframes slideUp {
from {
    transform: translateY(100%);
}
to {
    transform: translateY(60%);;
}
}
  


.custom-modal p {
    font-size: 1.1em;
    color: #ffffff;
    width: 80%;
    margin: 30% auto;
    text-align: left;
    font-family: "Pretendard-Semibold";
    line-height: 30px;
}

.modal-button {

    background-size: contain;
    background-image:url(../assets/Quiz/BtnCheck.svg);
    background-repeat: no-repeat; 
    width: 30%;
    height: 6vh;
    background-color: transparent;
    margin: 7% 10%;
    float: right;

    border: none;
    cursor: pointer;

    transition: background-color 0.3s ease, transform 0.3s ease; 
}


  

.modal-button:hover {
    background-image:url(../assets/Quiz/BtnCheckClick.svg);
  }

.layout-progressbar {
    padding-top: 15%;
    padding-bottom: 0;
    display: flex;
    gap: 1%;
}


.Progressbar-part {
    flex: 1;
    width: auto;
    height: 3vh;
    background-color: none; /* �⺻ ���� */
    background-size: contain;
    background-image: url(../assets/Quiz/ImgProgressBarBg.svg);
    background-repeat: no-repeat; 
    background-position: center;


}

.resultBox{
    height: 6%;
}

.Progressbar-part.completed {
    background-image: url(../assets/Quiz/ImgProgressBar.svg);
}

.quizContentText
{
    font-family: "Pretendard-Semibold";
    width: 90%;
    font-size: 0.9rem;
    text-align: left;
    margin-Top: 0;
    padding: 0 4%; 
    margin-left: 5%;
    margin-right: 5%;
    line-height: 1.6rem;
    color: #424242;
}

@media (max-height: 500px) {
    .layout-contents {
        width: 60%;
    }

    .layout-progressbar {
        padding-top: 3%;
        gap: 1%;
    }
    .QuizContents {
        width: 50%;
        height: 70vh;
        padding: 5% 15%;
    }

    .quizContentText {
        font-size: 0.8rem;
        line-height: 1rem;
    }

    .AR-button
    {
    width: 0%; /* Set the desired width of the button */
    }

    .Hint-button {
        width: 0%; /* Set the desired width of the button */ 
    }

    .resultBox{
        height: 0%;
    }

    .inputAnswer {
        width: 100%;
        height: 9vh;
        font-size: 0.8rem;
        margin-bottom: 0;

    }
    .submit-button {
        width: 0%; /* Set the desired width of the button */
        height: 0vh; /* Set the desired height of the button */

        padding:0%;
        margin-bottom: 0%;
    }
    
  }

  @media (max-height: 770px) {
    .custom-modal p {
        font-size: 0.9rem;
        width: 80%;
        line-height: 1.4rem;
    }
  }