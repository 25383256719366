
.mainContents {
    width: 80%;
    padding: 5%;
    text-align: center;
    margin: 0 auto;
    
}


.ImgOnboarding1 {
    width: 100%;
    height: 36vh;

    background: none; /* Removes the default background */
    border: none; /* Removes the default border */
    padding: 0; /* Removes any default padding */
    margin: 0; /* Removes any default margin */
    background-size: cover;
    background-image: url(../assets/Onboarding/ImgOnboarding1.svg);
    background-repeat: no-repeat; 
    background-position: center;
    
}

.ImgOnboarding2 {
    width: 100%;
    height: 36vh;

    background: none; /* Removes the default background */
    border: none; /* Removes the default border */
    padding: 0; /* Removes any default padding */
    margin: 0; /* Removes any default margin */
    background-size: cover;
    background-image: url(../assets/Onboarding/ImgOnboarding2.svg);
    background-repeat: no-repeat; 
    background-position: center;
    
}

.titleContents
{
    background: none; /* Removes the default background */
    border: none; /* Removes the default border */
    padding: 0; /* Removes any default padding */
    margin: 0; /* Removes any default margin */
    background-size: cover;
    background-image: url(../assets/Onboarding/ImgTitle.svg);
    background-repeat: no-repeat; 
    background-position: center;
    width: 100%;
    height: 16vh;
    margin: 5% auto;
}
.mainBackground
{
    margin: 0;
    width: 100%;
    height: 100vh;
    background: none; /* Removes the default background */
    border: none; /* Removes the default border */
    background-position: center;
    background-size: cover;
    background-image: url(../assets/Onboarding/ImgBgOnboarding.svg);
    background-repeat: no-repeat; 
}
.btnContents {
    width: 80%;
    margin: 50px auto;
    padding: 20px;
    text-align: center;
}

.startBtn{
    background: none; /* Removes the default background */
    border: none; /* Removes the default border */
    padding: 0; /* Removes any default padding */
    margin: 0; /* Removes any default margin */
    background-size: cover;
    background-image: url(../assets/Onboarding/BtnStart.svg);
    background-repeat: no-repeat; 
    background-position: center;
    width: 300px; /* Set the desired width of the button */
    height: 80px; /* Set the desired height of the button */
    cursor: pointer; /* Ensure the cursor changes to a pointer when hovered */
    transition: background-color 0.3s;
}

.startBtn:hover{
    background: none; /* Removes the default background */
    border: none; /* Removes the default border */
    padding: 0; /* Removes any default padding */
    margin: 0; /* Removes any default margin */
    background-size: cover;
    background-image: url(../assets/Onboarding/BtnStartClick.svg);
    background-repeat: no-repeat; 
    background-position: center;
    width: 300px; /* Set the desired width of the button */
    height: 80px; /* Set the desired height of the button */
    cursor: pointer; /* Ensure the cursor changes to a pointer when hovered */
    transition: background-color 0.3s;
}

.startMessage
{
    font-family: "Pretendard-SemiBold";
    width: 100%;
    height: 15vh;
    margin: 5% auto;
    color: #fffdf5;
    font-size:110%;
    line-height: 120%;

}

.nextBtn
{
    background: none; /* Removes the default background */
    border: none; /* Removes the default border */
    padding: 0; /* Removes any default padding */
    margin: 0; /* Removes any default margin */
    background-size: cover;
    background-image: url(../assets/Onboarding/BtnOnboardingNext.svg);
    background-repeat: no-repeat; 
    background-position: center;
    width: 300px; /* Set the desired width of the button */
    height: 80px; /* Set the desired height of the button */
    cursor: pointer; /* Ensure the cursor changes to a pointer when hovered */
    transition: background-color 0.3s;
}

